/**
 *FICHERO DE CONFIGURACION DE VARIABLES DE ENTORNO PARA ACERGA STG
 */
export const environment_data = {
    production: false,
    APIURL: 'https://stg-api-subasta.docpeixe.es',
    MQTT_SERVER_URL: 'mqtt.saecdata.net',
    MQTT_PROTOCOL: "wss",
    MQTT_USER: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGl0eSI6IjQ1NDAiLCJzdWIiOiI0NTQwIiwiZXhwIjoyNTM0MDIzMDA3OTksInR5cGUiOiJhY2Nlc3MifQ.Ixv37yUTfyIzUNmGU1sxHNAIq88uhhAm1Ieoe_GwRP8',
    MQTT_PASSWD: '12345678',
    MQTT_PORT: 8083,
    DEFAULT_TIPO_PANTALLA: "2", //tipo de pantalla por defecto // Valores esperados del 1 al 7
};